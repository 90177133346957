import Header from "../components/Header";
import useDeviceSize from "../hooks/useDeviceSize";

export default function Terms() {
  const { isDesktop } = useDeviceSize();

  return (
    <div className="h-dvh overflow-y-hidden">
      <Header title="이용약관" />
      <section className="termsPage h-full bg-white max-w-[360px] min-w-80 mx-auto px-[20px] overflow-y-hidden">
        <div
          className={`${isDesktop ? "h-[640px]" : "h-[86%]"} w-full overflow-y-auto flex flex-col border-[1px] border-solid border-gray0
      p-[16px] rounded-[8px] mt-[24px]`}
        >
          <h2 className="terms-title">제1조 목적</h2>
          <p>
            {`이 약관은 T키타카(이하 "운영팀"라 합니다.)이 운영하는
            홈페이지(https://www.gardenr.kr 이하 "홈페이지"라 합니다.) 에서
            제공하는 인터넷 관련 서비스(이하 "서비스"라 합니다.)를 이용함에 있어
            운영팀과 이용자의 권리와 의무 및 책임사항을 규정함을 목적으로
            합니다.`}
          </p>

          <h2 className="terms-title">제2조 약관의 효력 및 약관 외 준칙</h2>
          <ol>
            <li>
              1. 이 약관은 이용자가 홈페이지 가입(또는 이용)과 동시에
              적용됩니다.
            </li>
            <li>
              2. 이 약관은 홈페이지에 게재된 개인정보처리방침 등은 이 약관과
              상충되지 아니하는 한 이용자에게 적용됩니다.
            </li>
            <li>
              3. 이 약관에서 정하지 아니한 사항과 이 약관내용의 해석에 관하여는
              『전자상거래 등에서의 소비자 보호에 관한 법률』, 『약관의 규제에
              관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자
              보호지침』 및 관계법령 및 상관례에 따릅니다.
            </li>
          </ol>

          <h2 className="terms-title">제3조 약관의 명시와 개정</h2>
          <ol>
            <li>
              1. 운영팀은 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
              사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가
              알 수 있도록 운영팀의 초기 서비스화면(전면)에 게시합니다. 다만
              약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
              있습니다.
            </li>
            <li>
              {`2. 운영팀은 "전자상거래 등에서의 소비자보호에 관한 법률", "약관의
                규제에 관한 법률", "전자문서 및 전자거래기본법", "전자서명법",
                "정보통신망 이용촉진 및 정보보호에 관한 법률", "소비자기본법" 등
                관련법을 위배하지 않는 범위에서 임의로 이 약관을 개정할 수
                있습니다.`}
            </li>
            <li>
              {`3. 운영팀이 약관을 개정할 경우에는 적용 일자 및 개정 사유를
                명시하여 현행 약관과 함께 본 사이트의 초기화면에 그 적용일자 7일
                이전부터 적용일자 전일까지 공지합니다. 다만 이용자에게 불리하게
                약관내용을 변경하는 경우에는 최소한 30일 이상의 유예기간을 두고
                공지합니다. 이 경우 "운영팀"는 개정전 내용과 개정후 내용을
                명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.`}
            </li>
            <li>
              4. 운영팀이 개정약관을 공지하면서 회원에게 30일 기간 내에 거부의
              의사표시를 하지 않으면 동의의 의사표시가 표명된 것으로 본다는 뜻을
              명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지
              아니한 경우 회원이 개정약관에 동의한 것으로 간주합니다.
            </li>
            <li>
              5. 회원은 개정된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에
              동의하지 않을 경우 언제든지 자유롭게 서비스 이용을 중단하고 탈퇴할
              수 있습니다.
            </li>
            <li>
              {`6. 회원이 카카오계정을 연결하여 서비스를 이용하는 경우에는 해당
                회원의 카카오계정에 적용되는 이용약관 (카카오 통합서비스약관,
                카카오 통합 약관, 카카오 서비스 약관 중 하나가 됩니다. 이하
                총칭하여 '카카오 약관'이라 합니다) 과 그에 따른 운영정책 등이 본
                약관과 함께 적용됩니다. 서비스의 이용과 관련하여 본 약관과
                카카오 약관의 내용이 충돌하는 경우 본 약관의 내용을 우선
                적용합니다.`}
            </li>
          </ol>

          <h2 className="terms-title">제4조 용어의 정의</h2>
          <ol>
            <li>
              {`1. "이용자"란 "홈페이지"에 접속하여 이 약관에 따라 운영팀이
                제공하는 서비스를 받는 사람을 말합니다.`}
            </li>
            <li>
              {`2. "회원"이란 사이트에서 정한 소정의 절차를 거쳐 회원가입을 한
                자로서, 약관에 따라 운영팀이 제공하는 서비스를 이용할 수 있는
                자를 말합니다.`}
            </li>
            <li>
              {`3. "서비스"란 축의금 기록을 저장하는 서비스를 위한 소개 및 연계,
                이용정보, 이용편의 제공, 각종 이벤트 등과 같은 다양한 서비스를
                총칭합니다.`}
            </li>
            <li>
              4. 이 약관에서 사용하는 용어의 정의는 상기에서 정하는 것을
              제외하고는 거래관행 및 관계법령을 따릅니다.
            </li>
          </ol>

          <h2 className="terms-title">제5조 이용계약의 성립과 해지</h2>
          <ol>
            <li>
              1. 서비스 이용계약은 본 약관의 내용에 대한 동의와 이용신청에
              대하여 운영팀의 이용승낙으로 성립합니다.
            </li>
            <li>
              {`2. 본 이용약관에 대한 동의는 회원가입 당시 서비스의 '동의함' 버튼을
                누름으로써 의사표시를 합니다.`}
            </li>
            <li>
              3. 운영팀은 회원이 되고자 하는 자의 신청에 대하여 서비스 이용을
              승낙함을 원칙으로 합니다. 다만, 운영팀은 다음 각 호에 해당하는
              신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수
              있습니다.
            </li>
            <li className="sub-item">
              가. 가입신청자가 본 이용약관에 의하여 이전에 회원자격을 상실한
              적이 있는 경우, 단, 운영팀의 회원 재가입 승낙을 얻는 경우에는
              예외로 한다.
            </li>
            <li className="sub-item">
              나. 실명이 아니거나 타인의 명의를 이용한 경우
            </li>
            <li className="sub-item">
              다. 허위의 정보를 기재하거나 운영팀이 제시하는 내용을 기재하지
              않은 경우
            </li>
            <li className="sub-item">
              라. 기타 물리적·기술적 사유로 가입을 승낙할 수 없는 경우
            </li>
            <li>
              4. 회원은 언제든지 탈퇴를 요청할 수 있으며 “운영팀”는 즉시 회원
              탈퇴를 처리합니다.
            </li>
            <li>
              {`5. "운영팀"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다.
                이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일
                이상의 기간을 정하여 소명할 기회를 부여합니다.`}
            </li>
            <li className="sub-item">
              {`가. 회원이 회원가입 후, 계속하여 1년 이상 서비스를 이용하기
                위하여 접속한 기록이 없는 경우, "운영팀"는 정보통신망 이용촉진
                및 정보보호 등에 관한 법률에 의거 휴면회원으로 전환시킬 수
                있으며 휴면회원으로 전환된 경우 고객님의 회원정보가 별도
                분리되어 안전하게 저장됩니다. 휴면 전환된 계정은 간단한 절차를
                거친 후 계정 활성화가 가능합니다.`}
            </li>
          </ol>

          <h2 className="terms-title">제6조 서비스 제공 및 변경</h2>
          <ol>
            <li>1. 운영팀은 다음과 같은 서비스를 제공합니다.</li>
            <li className="sub-item">가. 보낸 축의금 기록저장</li>
            <li className="sub-item">나. 받은 축의금 기록저장</li>
            <li className="sub-item">
              다. 카카오톡 알림톡 : 회원가입, 및 광고성 정보수신에 동의
              시카카오톡 알림톡으로 광고성 정보를 알려드립니다. 만약 알림톡
              수신이 불가능하거나 수신 차단하신 경우에는 일반 문자메시지로
              회원가입에 관한 정보만 보내 드립니다. 카카오톡 알림톡을 통해
              안내되는 정보를 와이파이가 아닌 이동통신망으로 이용할 경우, 알림톡
              수신 중 데이터 요금이 발생할 수 있습니다.
            </li>
            <li className="sub-item">
              라. 기타 : 이용 관련 각종 정보제공 및 문의(상담) 서비스 등
              운영팀이 제공하는 각종 부가서비스
            </li>
            <li>
              2. 운영팀은 상품이 품절되거나 기술적 사양의 변경 등으로 더 이상
              제공할 수 없는 경우에는 장차 제공할 서비스의 내용을 변경할 수
              있습니다. 이 경우에는 변경된 서비스의 내용 및 제공일자를 명시하여
              공지합니다.
            </li>
          </ol>

          <h2 className="terms-title">제7조 서비스 중단</h2>
          <ol>
            <li>
              1. 운영팀은 다음 각호의 1에 해당하는 사유가 발생한 경우에는 그
              사유가 해소 될 때까지 서비스의 제공을 중단할 수 있습니다.
            </li>
            <li className="sub-item">
              가. 서비스 설비의 점검/보수/교체/고장 및 통신의 두절 등의 사유가
              발생하여 서비스 제공 또는 이용에 지장이 있는 경우
            </li>
            <li className="sub-item">
              나. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
              중지했을 경우
            </li>
            <li className="sub-item">
              다. 국가비상사태, 서비스 설비의 장애 또는 이용의 폭주 등으로
              서비스 제공 또는 이용에 지장이 있는 경우
            </li>
            <li className="sub-item">
              라. 운영팀 또는 서비스를 제공하기 위한 업체의 전산망이 해킹을
              당하는 등으로 인하여 이용자의 정보가 유출되는 등의 사유로
              이용자에게 피해가 발생될 우려가 있는 경우
            </li>
            <li>
              2. 제1항에 의한 서비스 중단의 경우에는 운영팀은 제16조에 정한
              방법으로 이용자에게 통지합니다.
            </li>
            <li>
              {`3. "운영팀"은 무료 서비스이므로 모든 책임을 부담하지 않습니다.`}
            </li>
          </ol>

          <h2 className="terms-title">제8조 개인정보보호</h2>
          <ol>
            <li>
              1. 운영팀은 서비스 개선 및 이용자 대상의 서비스 소개 등의 목적으로
              이용자의 동의 하에 관련 법령에 따라 추가적인 개인의 정보를 수집할
              수 있습니다. 운영팀은 이용자의 개인정보를 수집할 경우 필요한
              범위에서 최소한의 개인정보를 수집합니다.
            </li>
            <li>
              2. 운영팀은 개인정보를 수집, 이용하는 때에는 이용자에게 그 목적을
              고지하고 동의를 받습니다.
            </li>
            <li>
              3. 운영팀은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며,
              새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
              이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로
              합니다.
            </li>
            <li>
              4. 개인정보 수집 및 이용 등에 동의한 이용자는 언제든지 운영팀이
              가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
              있으며 운영팀은 이에 대해 지체 없이 필요한 조치를 취할 의무를
              집니다. 이용자가 오류의 정정을 요구한 경우에는 이용자는 그 오류를
              정정할 때까지 당해 개인정보를 이용하지 않습니다.
            </li>
            <li>
              5. 운영팀은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한
              것으로 설정해 두지 않습니다. 다만, 개인정보의 수집·이용·제공에
              관한 동의를 이용자가 거절하는 경우 관련 해당 서비스의 이용을 일부
              제한을 받을 수 있습니다.
            </li>
          </ol>

          <h2 className="terms-title">제9조 운영팀의 의무</h2>
          <p>
            1. 운영팀은 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
            서비스를 제공하는데 최선을 다합니다.
          </p>

          <h2 className="terms-title">제10조 정보의 제공</h2>
          <p>
            1. 운영팀은 이용자에게 서비스 이용에 필요가 있다고 인정되는 각종
            정보에 대해서 이용자의 포괄적 또는 개별적 사전 동의가 있을 경우
            전자우편이나 서신, 우편, SMS, 전화 등의 방법으로 이용자에게 제공할
            수 있습니다.
          </p>

          <h2 className="terms-title">제11조 자료의 게재, 용량의 제한</h2>
          <p>
            1. 운영팀은 이용자가 제공하는 정보, 자료에 대하여 운영팀의 공지,
            이용 안내 등을 통해 일정한 게재 기간이나 자료의 용량을 정할 수
            있습니다.
          </p>

          <h2 className="terms-title">제12조 분쟁해결</h2>
          <ol>
            <li>
              1. 운영팀은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그
              피해를 보상처리하기 위하여 피해보상처리기구인 고객만족센터를
              설치/운영합니다.
            </li>
            <li>
              2. 운영팀은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그
              사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게
              그 사유와 처리일정을 즉시 통보해 드립니다.
            </li>
            <li>
              3. 운영팀과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의
              피해구제신청이 있는 경우에는 공정거래위원회 또는 시/도지사가
              의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </li>
          </ol>

          <h2 className="terms-title">제13조 면책</h2>
          <ol>
            <li>
              1. 운영팀은 이용자의 부주의나 고의로 인해서 발생된 어떠한 손해에
              대해서도 책임을 지지 않습니다.
            </li>
            <li>
              2. 운영팀은 이용자가 운영팀의 서비스로부터 기대한 이익을 얻지
              못하였거나, 서비스를 통해 취득한 자료를 취사선택하거나 이용함으로
              인하여 입게 된 손해에 대하여 책임을 지지 않습니다.
            </li>
            <li>
              3. 운영팀은 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여
              책임을 지지 않습니다.
            </li>
            <li>
              4. 운영팀은 이용자가 서비스에 게재한 각종 정보, 자료, 사실의
              신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
            </li>
            <li>
              5. 운영팀은 이용자가 서비스를 이용하면서 얻은 자료로 인한 손해에
              대하여 책임을 지지 않습니다. 또한 운영팀은 이용자가 서비스를
              이용하며 타 이용자로 인해 입게 되는 정신적 피해에 대하여 보상할
              책임을 지지 않습니다.
            </li>
            <li>
              6. 운영팀은 천재지변 등의 불가항력으로 인해 서비스를 제공할 수
              없게 되어 발생한 손해에 대하여는 책임을 지지 않습니다.
            </li>
            <li>
              7. 운영팀이 제휴 사이트와의 계약에 의해 서비스를 제공하는 경우,
              제휴 사이트가 제공하는 서비스로 인하여 발생하는 어떠한 손해에
              대하여도 책임을 지지 않습니다.
            </li>
            <li>
              8. 운영팀은 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는
              이용자가 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가
              발생한 경우 책임을 지지 않습니다.
            </li>
          </ol>

          <h2 className="terms-title">제14조 통지</h2>
          <ol>
            <li>
              1. 운영팀이 이용자에 대하여 통지를 하는 경우 이용자가 운영팀에
              등록한 전자우편 주소로 할 수 있습니다.
            </li>
            <li>
              2. 운영팀은 불특정다수 이용자에게 통지를 해야 할 경우 공지
              게시판을 통해 7일 이상 게시함으로써 개별 통지에 갈음할 수
              있습니다.
            </li>
          </ol>

          <h2 className="terms-title">제15조 재판권 및 준거법</h2>
          <ol>
            <li>
              1. 운영팀과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은
              서울중앙지방법원 또는 대한민국의 민사소송법에 따른 법원을
              관할법원으로 합니다.
            </li>
            <li>
              2. 운영팀과 이용자가 제기된 전자상거래 소송에는 대한민국법을
              적용합니다.
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
